import React, { useCallback, useEffect, useMemo } from 'react';
import { NextPage } from 'next';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { EventsActions, OrganizationsActions } from 'store/actions';
import { EventsSelectors, OrganizationsSelectors } from 'store/selectors';
import { getErrorFromStates, isFetchingStates } from 'store/utils';

import withRouteValidation from 'hocs/withRouteValidation';
import withGetInitialPropsRouteValidation from 'hocs/withGetInitialPropsRouteValidation';

import { UserHeader } from 'components/common/Header';
import { TabTitle } from 'components/common/TabTitle';
import OrganizationScreen from 'components/screens/OrganizationScreen';
import RenderComponent from 'components/common/RenderComponent';
import { RootState } from 'store/rootReducer';

interface IProps {
  organizationId: number;
}

const querySchema = Yup.object({
  organizationId: Yup.number().required(),
});

const OrganizationPage: NextPage<IProps, any> = ({ organizationId }) => {
  const dispatch = useDispatch();

  const organizationState = useSelector((state: RootState) =>
    OrganizationsSelectors.organizationStateById(state, organizationId),
  );

  const stateSelector = useMemo(
    () =>
      createStructuredSelector({
        upcomingEventsState: EventsSelectors.upcomingEventsState(),
        pastEventsState: EventsSelectors.pastEventsState(),
      }),
    [],
  );

  const { pastEventsState } = useSelector((state: RootState) => stateSelector(state, { ordering: 'start_at' }));
  const { upcomingEventsState } = useSelector((state: RootState) => stateSelector(state, { ordering: 'session_date' }));

  const handleToggleFavoriteEvent = useCallback(
    (eventId: any) => dispatch(EventsActions.toggleFavoriteEvent.request({ id: eventId })),
    [dispatch],
  );

  useEffect(() => {
    if (organizationState.entity) {
      const { id, settings } = organizationState.entity;
      const params = {
        organization: id,
        page_size: 100,
        ...(settings && {
          include_suborganizations: settings.can_include_suborganizations_events,
        }),
      };

      dispatch(EventsActions.getUpcomingEvents.reset({ params: { ...params, ordering: 'session_date' } }));
      dispatch(EventsActions.getUpcomingEvents.request({ params: { ...params, ordering: 'session_date' } }));
      dispatch(EventsActions.getPastEvents.reset({ params: { ...params, ordering: '-start_at' } }));
      dispatch(EventsActions.getPastEvents.request({ params: { ...params, ordering: '-start_at' } }));
    }
  }, [dispatch, organizationState.entity]);

  return (
    <>
      <UserHeader />
      <RenderComponent
        layout
        isLoading={isFetchingStates(organizationState)}
        errors={getErrorFromStates(organizationState)}
        renderContent={() => (
          <>
            <TabTitle directTitle={organizationState.entity.title} signLabel="general" />
            <OrganizationScreen
              organization={organizationState.entity}
              upcomingEvents={upcomingEventsState.entities}
              pastEvents={pastEventsState.entities}
              isUpcomingEventFetching={upcomingEventsState.isFetching}
              isPastEventFetching={pastEventsState.isFetching}
              onToggleFavoriteEvent={handleToggleFavoriteEvent}
            />
          </>
        )}
      />
    </>
  );
};

// todo change to getServerSide
OrganizationPage.getInitialProps = withGetInitialPropsRouteValidation(querySchema, async ({ store, query }: any) => {
  const organizationId = Number(query.organizationId);

  if (organizationId) {
    store.dispatch(OrganizationsActions.getOrganization.request({ id: organizationId }));

    return {
      organizationId,
    };
  }

  return {};
});

export default withRouteValidation(OrganizationPage);
